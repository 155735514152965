/* eslint-disable */
import { getEnv } from './analytics';

var env = getEnv(window.location.host);
env = env ? env : 'local';
let mainURL = window.location.origin + window.location.pathname;
mainURL = mainURL.replace(/\/$/, "");

const local = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: 'http://localhost:3000',
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase: 'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au/participants',
  validateIDVReq: 'validate-request',
  dvsVerify: 'verify-documents',
  parRequest: 'create-par',
  idToken: 'idtoken',
};

const uat = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au/participants',
  validateIDVReq: 'validate-request',
  dvsVerify: 'verify-documents',
  parRequest: 'create-par',
  idToken: 'idtoken',
};

const fn2 = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://slot2.org002.t-dev.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au/participants',
  validateIDVReq: 'validate-request',
  dvsVerify: 'verify-documents',
  parRequest: 'create-par',
  idToken: 'idtoken',
};

const fn3 = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://slot4.org002.t-dev.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.sandbox.connectid.com.au/participants',
  validateIDVReq: 'validate-request',
  dvsVerify: 'verify-documents',
  parRequest: 'create-par',
  idToken: 'idtoken'
};
const prod = {
  baseUrl: mainURL,
  baseHashUrl: mainURL + '/#',
  originUrl: window.location.origin,
  apiHost:'https://tapi.telstra.com/',
  apiBase:'presentation/v1/idverification/',
  cidParticipants: 'https://data.directory.connectid.com.au/participants',
  validateIDVReq: 'validate-request',
  dvsVerify: 'verify-documents',
  parRequest: 'create-par',
  idToken: 'idtoken'
};

const config = (env === 'devel' || env === 'uat') ? uat : env === 'fn2' ? fn2 : env === 'fn3' ? fn3 : env === 'production' ? prod : local;

export default { ...config };