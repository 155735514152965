import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Column,
  TextStyle,
  ActionButton,
  Divider,
  InlineLink,
  Lozenge,
  MessageInline
} from "@able/react";
import CustomSpacing from "../../components/Spacing";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import ConnectIdBtn from "../../components/ConnectIdBtn";
import PageTitle from "../../components/PageTitle";
import { updateAnalyticsData } from '../../helpers/analytics';
import { getSession, setSession } from "../../helpers";
import Commonwealth_Bank from "../../assets/Commonwealth_Bank.png";
import National_Australia_Bank from "../../assets/National_Australia_Bank.svg";
import ANZ_Plus from "../../assets/ANZ-Plus-logo.svg";
import Westpac_Bank from "../../assets/Westpac_Bank.svg";
import GlobalBanner from "../../components/GlobalBanner";
import { clientErrors } from "../../helpers/messages";
import  secureLocalStorage  from  "react-secure-storage";
import { isMobile } from "../../helpers/deviceType";
const privacy_link = "https://www.telstra.com.au/privacy#info-collect";
const alternate_link = "https://www.telstra.com.au/support/account-payment/id-check-for-new-account";
const contact_link = "https://www.telstra.com.au/contact-us";
const visit_connect_link = "https://connectid.com.au/";
const terms_condition_link = "https://www.telstra.com.au/support/account-payment/connect-id#t&c";

const banksList = [
  {id:1,name:"Commonwealth Bank",path:Commonwealth_Bank,show:true},
  {id:2,name:"National Australia Bank",path:National_Australia_Bank,show:true},
  {id:3,name:"ANZ Plus",path:ANZ_Plus,show:true},
  {id:4,name:"Westpac Bank",path:Westpac_Bank,show:true}
]

export default function PageConfirmIdentity() {
  const navigation = useNavigate();
  const titleRef = useRef(null);
  const idUnavialableRef = useRef(null);
  const [title] = useState("Confirm your identity");
  const [isLoading, setIsLoading] = useState(true);
  const [isCIDShowError, setCIDShowError] = useState(getSession("isCIDError"));
  const { validateIDVResponse,commonError,isPageLoaded } = useSelector((state) => state.verifyIdentify);
  const [isAuthenticated] = useState(getSession("isAuthenticated"));
  useEffect(() => {
    if (isPageLoaded) {
      navigation("/");
    }
    if (getSession("cid_store") && Object.keys(getSession("cid_store")).length > 0) {
        setSession("cid_store",false);
    }
    if (isCIDShowError && getSession("cid-error")) {
      if(getSession("cid-error") === clientErrors.cid_login_cancel
      || getSession("cid-error") === clientErrors.cid_aborted_cancel
      || getSession("cid-error") === clientErrors.cid_declined_cancel){
        setCIDShowError(false);
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    if(isMobile()){
      secureLocalStorage.removeItem("user_token");
      secureLocalStorage.removeItem("gotoUrl");
      secureLocalStorage.removeItem("authdata");
      secureLocalStorage.removeItem("cid_store");
      localStorage.removeItem("taskError")
    }
  }, [])

  useEffect(() => {
    if (validateIDVResponse.contactUuid && isAuthenticated) {
      updateAnalyticsData(title, null, null, {
        userContactUUID: validateIDVResponse.contactUuid || "",
        userName: validateIDVResponse.userName || "",
        loggedInUsing: "Telstra ID: b2c-idcheck",
      })
    }
  },[isAuthenticated])
  
  useEffect(() => {
    if (isCIDShowError) {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "error",
          "eventType": "Technical",
          "eventCategory": "cid-error",
          "eventName": getSession("cid-error")
        },
        "attributes":{}
      })
    }
  },[isCIDShowError])
  
  // handling analytics updation
  const handleAnalytics = (id, eventName, btnType) => {
    let eventCategory = btnType ? btnType : "buttonClick";
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "click",
        "eventType": "clickTrack",
        "eventCategory": eventCategory,
        "eventName": eventName
      },
      "attributes":{}
    })
  }
  // handling click events
  const handleOnClick = (id, eventName, btnType) => {
    setSession('isCIDError',false);
    setSession('cid-error',"");
    handleAnalytics(id,eventName, btnType);
    setIsLoading(true)
    if (id === "connectID") {
      setTimeout(() => {
        navigation("/connect-id");
      }, 500);
    }
    if (id === 'manual') {
      setTimeout(() => {
        navigation("/verify-identity");
      }, 500);
    }
  }

  return (
    <PageContent title={title}>
      {isLoading ? <Loader />
        :
        <Grid padding={false}>
          <Column cols={12}>
            <PageTitle ref={titleRef} title={title} focusTitle={true} />
            <div ref={idUnavialableRef} role="alert">
              <GlobalBanner pageCID = {true} />
            </div>
            <div role="alert">
            {
              isCIDShowError &&
              <CustomSpacing bottom="spacing3x" top="spacing3x">
                <MessageInline
                developmentUrl={window.location.origin + '/able-sprites.svg'} variant="Error">
                  <TextStyle alias="LabelA1">We are unable to verify your identity via ConnectID</TextStyle>
                    <CustomSpacing top="spacing1x" />
                  <TextStyle>Try another provider or verify manually with your ID document</TextStyle>
                </MessageInline>
              </CustomSpacing>
            }
            </div>
            <CustomSpacing top="spacing1x" bottom="spacing3x">
              <TextStyle>You can verify your identity via one of the following:</TextStyle>
            </CustomSpacing>
            <CustomSpacing bottom="spacing3x">
              <Lozenge label='Option 1' variant='Neutral'/>
            </CustomSpacing>
            <TextStyle alias="HeadingC" element="h2">Verify with ConnectID</TextStyle>
            <CustomSpacing top="spacing1x" bottom="spacing2x">
              <TextStyle>
              ConnectID lets you verify who you are using information stored with an organisation you already trust, like your bank.
              </TextStyle>
            </CustomSpacing>
            <CustomSpacing className="connectID_bg" top="spacing2x" bottom="spacing2x" left="spacing3x" right="spacing3x">
              <TextStyle element="ul" className="connectId-Ul">
                <li>Reduce oversharing personal data by only sharing your name & date of birth via ConnectID, without sharing ID documents</li>
                <li>Australian government accredited</li>
                <li>Telstra does not collect or store any login or transaction details from your chosen organisation when verifying via ConnectID</li>
                <li className="terms_condition">Read the <a href={terms_condition_link} target="_blank" rel="noreferrer" >Terms & Conditions</a></li>
              </TextStyle>
            </CustomSpacing>
            <CustomSpacing top="spacing3x">
              <ConnectIdBtn handleOnClick={handleOnClick} />
            </CustomSpacing>
            
            <CustomSpacing top="spacing2x" bottom="spacing2x" left="spacing3x" right="spacing3x">
              <TextStyle className="t-text-center">Available on</TextStyle>
              <TextStyle className="t-text-center images-group">
                {
                  banksList.map((el, index) => 
                    el.show && <img src={el.path} alt={el.name} key={index} />
                  )
                }
              </TextStyle>
            </CustomSpacing>
            <InlineLink className="t-text-center able-InlineLink">
              <CustomSpacing bottom="spacing1x">
              <TextStyle alias="FinePrintA">No banking or financial information is shared.</TextStyle>
              </CustomSpacing>
              <TextStyle alias="FinePrintA">Visit <a href={visit_connect_link} target="_blank" rel="noreferrer" >ConnectID.com.au</a> for more details.</TextStyle>
            </InlineLink>

            <CustomSpacing top="spacing4x" bottom="spacing5x">
              <Divider/>
            </CustomSpacing>
            <Lozenge label='Option 2' variant='Neutral' />
            <CustomSpacing top="spacing3x" bottom="spacing1x">
            <TextStyle alias="HeadingC" element="h2">Verify manually </TextStyle>
            </CustomSpacing> 
            <TextStyle>
              Can't find your identity provider listed above? You can verify your identity manually. By choosing to do so, you allow Telstra to collect and store your full name, date of birth, and ID details.
            </TextStyle>
            {/* <CustomSpacing top="spacing3x" >            
            </CustomSpacing>
             <TextStyle>
              Use an ID document to verify your identity. By choosing to do so, you allow Telstra to collect and store your full name, date of birth, and ID details. 
            </TextStyle> */}
            <CustomSpacing top="spacing3x" bottom="spacing3x">
              <ActionButton
                developmentUrl={
                  window.location.origin + "/able-sprites.svg"
                }
                element="button"
                label="Enter your ID details"
                to="/"
                type="button"
                className="fullwidth"
                variant="MediumEmphasis"
                onClick={() =>
                  handleOnClick("manual", "Enter your ID details")
                }
              />
            </CustomSpacing>
            <TextStyle>ID documents we accept: </TextStyle>
            <CustomSpacing top="spacing3x" bottom="spacing3x" left="spacing2x">
              <TextStyle element="ul">
                <li>Australian Driver's Licence</li>
                <li>Australian or International Passport </li>
                <li>Medicare card</li>
                <li>ImmiCard</li>
              </TextStyle>
            </CustomSpacing>
            <InlineLink>
              <TextStyle alias="FinePrintA">
              Telstra is collecting your personal information in order to comply with its regulatory obligations, including Australian Communications and Media Authority (ACMA) rules on ID checks for prepaid mobile services. Information about how we collect, store, use and share personal information can be found in our <a href={privacy_link} target="_blank" rel="noreferrer" >Privacy Statement</a>.
              </TextStyle>
            </InlineLink>
            <CustomSpacing top="spacing3x">
              <InlineLink>
                <TextStyle alias="FinePrintA">
                Having trouble verifying your ID? You can verify your identity in store using <a href={alternate_link} target="_blank" rel="noreferrer" >alternative forms of ID</a> or <a href={contact_link} target="_blank" rel="noreferrer" >Contact us</a>.
                </TextStyle>
              </InlineLink>
            </CustomSpacing>
          </Column>
        </Grid>
      }
    </PageContent>
  );
}