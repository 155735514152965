import { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import PageContent from "../PageContent";
import Loader from "../../components/Loader";
import { updateAnalyticsData } from '../../helpers/analytics';
import { validateIDVRequest,IDPSelectorAuthToken } from '../../redux/api';
import { setSession, getSession } from "../../helpers";
import { serverErrors, errorRefId } from "../../helpers/messages";
import { saveValidateIDVResponse, setPageLoaded, setCommonError, setConnectIdData } from '../../redux/verifyIdentifySlice';
import { useAuth } from "react-oidc-context";
import { isMobile } from "../../helpers/deviceType";
import  secureLocalStorage  from  "react-secure-storage";

export default function PageAuthenticate() {
  const { validateIDVResponse } = useSelector((state) => state.verifyIdentify);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [title] = useState("Authenticate");
  let mounted = true;
  const auth = useAuth();
  
  useEffect(() => {
    if(isMobile()){
      if (secureLocalStorage.getItem("user_token")) {
        updateAnalyticsData(title, null, {
          "eventInfo": {
            "eventAction": "impression",
            "eventType": "idvConnectID",
            "eventCategory": "bankToIdv",
            "eventName": `IDV:BANK:${secureLocalStorage.getItem("pname")},REFID:${secureLocalStorage.getItem("cid_store").referenceId},FLOW:toIDV,AUTH:${secureLocalStorage.getItem("isAuthenticated")},ISS:NA`
          },
          "attributes":{}
        })
        setSession("user_token",secureLocalStorage.getItem("user_token"));
        setSession("gotoUrl", secureLocalStorage.getItem("gotoUrl"));
        setSession("isAuthenticated", secureLocalStorage.getItem("isAuthenticated"));
        if (secureLocalStorage.getItem("authdata")) {
          setSession("AuthData",secureLocalStorage.getItem("authdata"));
        }
        
        localStorage.removeItem("loadAuth");
        setSession("cid_store",secureLocalStorage.getItem("cid_store"));
        sessionStorage.setItem("loadAuth",true)
      }
      
      if (localStorage.getItem("taskError")) {
        sessionStorage.setItem("isCIDError",true);
        sessionStorage.setItem("cid_store", false);
        sessionStorage.setItem("cid-error", localStorage.getItem("cid-error"));
        localStorage.removeItem("taskError")
        navigation("/confirm-identity");
      }
    }
  }, [localStorage])

  const removeSecureStorage = () => {
    secureLocalStorage.removeItem("user_token");
    secureLocalStorage.removeItem("gotoUrl");
    secureLocalStorage.removeItem("authdata");
    secureLocalStorage.removeItem("isAuthenticated");
    secureLocalStorage.removeItem("cid_store");
    secureLocalStorage.removeItem("pname")
  }

  useEffect(() => {
    localStorage && localStorage.removeItem("userToken")
    if (!getSession("loadAuth")) {
      auth.signinRedirect()
    }
    if (mounted && getSession("loadAuth")) {
      if (!getSession("cid_store") && Object.keys(validateIDVResponse).length === 0) {
        validateIDVRequestCall()
      }
      setTimeout(() => {
        if ((getSession("cid_store") && Object.keys(getSession("cid_store")).length > 0) || secureLocalStorage.getItem("cid_store")) {
          runIDPSelectorAuthToken()
        }
      },1000)
    }
    mounted = false;
  }, [])

  // updating analytics on every event within the document
  const updateAnalyticsDataFunc = (code,eventName) => {
    updateAnalyticsData(title, null, {
      "eventInfo": {
        "eventAction": "error",
        "eventType": "Technical",
        "eventCategory": code,
        "eventName": eventName
      },
      "attributes": {}
    })
  }

  const validateIDVRequestCall = () => {
    dispatch(setPageLoaded(false))
    validateIDVRequest()(dispatch)
    .then((response) => {
      if (response.status === 200) {
        dispatch(saveValidateIDVResponse(response.data))
        setSession("clientCode",response.data.clientCode)
        setSession("cid_store",false)
        navigation("/confirm-identity");
      } else {
        const eKey = "validateIDVRequest";
        const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
        const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKey] + serverErrors[eKey][err].errRefNo;
        dispatch(setCommonError({ ...serverErrors[eKey][err], refCode }))
        updateAnalyticsDataFunc(err,serverErrors[eKey][err].errDesc)
        if (serverErrors[eKey][err] && serverErrors[eKey][err].redirectTo === "gotoUrl") {
          window.location = getSession("gotoUrl");
        } else {
          setSession("cid_store",false)
          navigation("/error");
        }
      }
    })
    .catch((error) => {
      setSession("cid_store",false)
      navigation("/error");
    });
  }

  const redirectTaskCompleted = (type) => {
    if (isMobile()) {
      removeSecureStorage()
      if (getSession("isSameWindow")) {
        window.location = getSession("gotoUrl");
        return
      }
      localStorage.setItem("taskCompleted",true)
      document.getElementById("textID").click();
      window.close();
    } else {
      window.location = getSession("gotoUrl");
    }
  }
  
  const redirectTaskError = (type) => {
    if (isMobile()) {
      removeSecureStorage()
      if (getSession("isSameWindow")) {
        if (type === "validateToken") {
          validateIDVRequestCall()
          return
        }
        setSession("isCIDError",true);
        navigation("/confirm-identity");
        return
      }
      localStorage.setItem("taskError", true)
      document.getElementById("textID").click();
      window.close();
    } else {
      if (type === "validateToken") {
        validateIDVRequestCall()
        return
      }
      setSession("isCIDError",true);
      navigation("/confirm-identity");
    }
  }

  const runIDPSelectorAuthToken = () => {
    const cid_code = sessionStorage.getItem("cid_code");
    const cid_state = sessionStorage.getItem("cid_state");
    const cid_iss = sessionStorage.getItem("cid_iss");
    const eKey = "cidParToken";
    if (isMobile()) {
      updateAnalyticsData(title, null, {
        "eventInfo": {
          "eventAction": "impression",
          "eventType": "idvConnectID",
          "eventCategory": "bankToToken",
          "eventName": `IDV:BANK:${secureLocalStorage.getItem("pname")},REFID:${getSession("cid_store").referenceId},FLOW:tokenApi,AUTH:${getSession("isAuthenticated")},ISS:${cid_code && cid_state && cid_iss ? true : false}`
        },
        "attributes": {}
      })
    }
    dispatch(setPageLoaded(false));
    if (cid_code && cid_state && cid_iss) {
      IDPSelectorAuthToken(cid_code , cid_state, cid_iss)(dispatch)
        .then((response) => {
        if (response.message || !response.status || response.status===401){
          setSession("tokenExpired",true)
        } else {
          setSession("tokenExpired",false)
        }
        if (response.status === 200) {
          // dispatch(setConnectIdData(response.data));
          redirectTaskCompleted()
        } else {
          const err = "err_" + response.status + (response.errors ? "_" + response.errors[0].code : "");
          const refCode = errorRefId.prefix + errorRefId.delimeter + errorRefId[eKey] + serverErrors[eKey][err].errRefNo;
          dispatch(setCommonError({ ...serverErrors[eKey][err], refCode }))
          updateAnalyticsDataFunc(err,serverErrors[eKey][err].errDesc)
          if (serverErrors[eKey][err] && serverErrors[eKey][err].redirectTo === "gotoUrl") {
            redirectTaskCompleted()
          } else {
            redirectTaskError()
          }
        }
        sessionStorage.removeItem("cid_code")
        sessionStorage.removeItem("cid_state")
        sessionStorage.removeItem("cid_iss")
      })
      .catch((error) => {
        redirectTaskError()
      });
    }else{
      redirectTaskError("validateToken")
    }
  }
  
  return (
    <PageContent title={title}>
      <Loader type="focus" />
      <a id="textID" target="cid_callback"></a>
    </PageContent>
  );
}